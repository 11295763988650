<template>
  <div v-if="product" class="position-relative overflow-hidden show-info-by-hover">
    <div id="productCard" class="card text-right productCard">
      <router-link
        :to="
          product
            ? {
                name: 'product.detail',
                params: { id: product.id, slug: product.slug },
              }
            : ''
        "
      >
        <div class="card-img">
          <img
            loading="lazy"
            v-if="product && mainImage"
            class="w-100 h-100"
            :src="getImage(mainImage, 'lg')"
            :alt="product.title"
          />
          <img
            loading="lazy"
            v-else
            class="w-100 h-100 skeleton-image"
            :alt="product ? product.title : ''"
          />
        </div>
        <div class="card-body">
          <h4
          v-if="product"
            class="card-title text-dark fontsize-medium"
            :title="product.title"
          >
            {{ product.title }}
          </h4>
          <div
            v-if="product.status == 'available'"
            class=" card-text"
          >
            <p
              class="d-flex align-items-center text-muted fontsize-medium pr-2 m-0 "
              v-if="discountAmount != 0"
            >
              <del class="pr-1" :class="{ 'fontsize-16': discountAmount }"
                >{{ (mainPrice + discountAmount) | price }} تومان</del
              >
              <DiscountSvg />

            </p>
            <p
              :class="{ 'fontsize-15': discountAmount }"
              class="text-danger font-weight-bold fontsize-medium m-0"
            >
              {{ mainPrice | price }} تومان
            </p>
          </div>
          <div v-else class="hide-by-hover">
            <span
              v-if="product.status == 'out_of_stock'"
              class="text-danger fontsize-medium"
            >
              ناموجود
            </span>
            <span
              v-if="product.status == 'soon'"
              class="text-info fontsize-medium"
            >
              به زودی
            </span>
          </div>
        </div>
      </router-link>
    </div>
    <!-- hover -->
    <div id="productCardHover" class="position-absolute">
      <div class="card-body text-center">
        <h4 class="text-dark fontsize-medium" v-if="product" :title="product.title">
          {{ product.title }}
        </h4>
        <div
          v-if="product.status == 'available'"
          class=" card-text"
        >
          <p
            class="d-flex align-items-center text-muted fontsize-medium pr-2 m-0 "
            v-if="discountAmount != 0"
          >
            <del class="pr-1" :class="{ 'fontsize-16': discountAmount }"
              >{{ (mainPrice + discountAmount) | price }} تومان</del
            >
            <DiscountSvg />

          </p>
          <p
            :class="{ 'fontsize-15': discountAmount }"
            class="text-danger font-weight-bold fontsize-medium m-0"
          >
            {{ mainPrice | price }} تومان
          </p>
        </div>
        <div v-else class="hide-by-hover">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-danger fontsize-medium"
          >
            ناموجود
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-info fontsize-medium"
          >
            به زودی
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <router-link
          :to="
            product != null
              ? { name: 'product.detail', params: { id: product.id } }
              : ''
          "
          v-if="product != null"
          type="button"
          class="bg-color-theme fontsize-mini py-2 px-3 weight-bold text-white"
        >
          مشاهده محصول
          <BagSvg />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BSkeleton } from "bootstrap-vue";
import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import DiscountSvg from "@/parts/Front/components/svg/DiscountSvg.vue";
import BagSvg from "@/parts/Front/components/svg/BagSvg.vue";

export default {
  name: "Product",
  components: { DiscountSvg, BagSvg, BSkeleton },
  props: ["product", "lowOpacity"],
  data() {
    return {
      showVideoBox: false,
      playerOptions: null,
    };
  },
  methods: {
    getImage,
    showVideo(video) {
      this.playerOptions = video;
      this.showVideoBox = true;
    },
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.major_final_price.amount : null;
    },
    discountAmount() {
      return this.product
        ? this.product.major_final_price.discount_price
        : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
  },
};
</script>

<style scoped>
#productCard .card-img {
  max-width: 100%;
}
#productCard .card-title {
  max-height: 3.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#productCardHover {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px 3px 0 0;
  width: 98%;
  bottom: 0;
  transform: scaleY(0);
  right: calc((100% - 98%) / 2);
  transition: all 0.3s ease-out;
  padding: 0 0 1.25rem 0;
}
.show-info-by-hover:hover #productCardHover {
  transform: scaleY(1);
}

.card-text{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  #productCard .card-title {
    max-height: 2.4rem;
  }
}
.fontsize-14 {
  font-size: 14px !important;
}
</style>
<style>
@media (max-width: 767px) {
  .productCard .card-body {
    padding: 1.25rem 0.25rem;
  }
}
</style>
